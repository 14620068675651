import tws from "../assets/images/tws.jpg"
import finance from "../assets/images/finance.jpg"

export const projectData = [
    {
        title: "Quant Dashboard",
        img: `${finance}`,
        date: ` (December 2024)`,
        description:
            "The Quant-Dashboard is an interactive web-based application designed for analyzing stock fundamentals, clustering companies based on financial metrics, and visualizing data insights. This tool uses machine learning techniques to group stocks with similar financials and provides key financial ratios for fundamental analysis.",
        techno1: "Python",
        techno2: "Streamlit",
        code: "https://github.com/Ish2K/Quant-Dashboard",
        demo: "https://quantitative-dashboard.streamlit.app/",
        scrollY: "-50%",
        // icon: "🛒",
    },
    {
        title: "Delta Neutral Trading Bot",
        img: `${tws}`,
        date: ` (September 2023)`,
        description:
            "The aim of this project is to develop a delta neutral trading strategy using Python and the Interactive Brokers (IB) API. The strategy involves creating a portfolio of options and underlying assets where the net delta is zero, thereby minimizing the portfolio's sensitivity to price movements of the underlying asset. This project will involve data retrieval, portfolio construction, and automated trading using the Interactive Brokers API.",
        techno1: "Python",
        code: "https://github.com/Ish2K/IBKRDeltaTrading",
        // demo: "",
        scrollY: "-50%",
        // icon: "🛒",
    },
    {
        title: "IBKR Trading System",
        img: `${tws}`,
        date: ` (Dec 2023)`,
        description:
            "This project aims to develop a comprehensive system for analyzing tick data using Python. Tick data, which includes every market event such as trades and quotes, provides granular insights into market behavior. Analyzing this data can uncover patterns, trends, and anomalies that are not visible in aggregated data. The project will involve data retrieval, storage, analysis, and visualization to provide meaningful insights from tick-level data.",
        techno1: "Python",
        code: "https://github.com/Ish2K/IBKRTrader",
        // demo: "",
        scrollY: "-74%",
        // icon: "🍪",
    }
];