/* eslint-disable jsx-a11y/img-redundant-alt */
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkIcon from "@mui/icons-material/Link";
import { useContext, useState } from "react";
import StyleContext from "../../contexts/StyleContext";

function ProBox({
  title,
  img,
  description,
  techno1,
  techno2,
  code,
  demo,
  scrollY,
  cName,
  icon,
  date,
}) {
  const [scroll, setScroll] = useState(false);
  const { isDark } = useContext(StyleContext);

  return (
    <>
      <div className={`pro pro__1 ${cName}`}>
        <div className="pro__img">
          {demo ? (
            <a target="_blank" href={demo} rel="noreferrer">
              <img
                src={img}
                height={500}
                width={500}
                alt="website image"
                style={{
                  transform: scroll ? `translateY(${scrollY})` : "translateY(0%)",
                  transition: "transform 8s ease-in-out",
                }}
                onMouseEnter={() => setScroll(true)}
                onMouseLeave={() => setScroll(false)}
              />
            </a>
          ) : (
            <img
              src={img}
              height={500}
              width={500}
              alt="website image"
              style={{
                transform: scroll ? `translateY(${scrollY})` : "translateY(0%)",
                transition: "transform 8s ease-in-out",
              }}
              onMouseEnter={() => setScroll(true)}
              onMouseLeave={() => setScroll(false)}
            />
          )}
        </div>
        <div className="pro__text">
          <h3 style={{ color: "#55198b", fontSize: "clamp(12px, 2vw, 20px)" }}>
            {title} <span className="date-class">{date}</span> {icon}
          </h3>
          <p style={{ color: isDark ? "#fff" : "black" }}>{description}</p>
          <div className="stack">
            <button className="btn-tech">{techno1}</button>
            {/* <button className="btn-tech">{techno2}</button> */}
          </div>
          <div className="links">
            {code && (
              <a target="_blank" href={code} rel="noreferrer">
                <button className="Btn">
                  <GitHubIcon />
                  <span className="text">Github</span>
                </button>
              </a>
            )}
            {demo && (
              <a target="_blank" href={demo} rel="noreferrer">
                <button className="pro-button" data-text="Awesome">
                  <span
                    style={{ color: isDark ? "#fff" : "red" }}
                    className="actual-text"
                  >
                    &nbsp;LiveDemo&nbsp;
                  </span>
                  <span aria-hidden="true" className="hover-text">
                    &nbsp;LiveDemo&nbsp;
                  </span>
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProBox;
